import React from "react"
import { Link } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';


class Navigation extends React.Component {

    render() {
        let navigation
        let path;
        if(typeof window != "undefined"){
            path = window.location.pathname
            if(path === "/"){
                navigation = <><a className="nav-link" onClick={() => scrollTo('#skills')}>.skills()</a><a className="nav-link" onClick={() => scrollTo('#experience')}>.xp()</a></>
            }else{
                navigation = <><a className="nav-link" href="/">.skills()</a><a className="nav-link" href="/">.xp()</a></>
            }
        }else{
            navigation = <><a className="nav-link" onClick={() => scrollTo('#skills')}>.skills()</a><a className="nav-link" onClick={() => scrollTo('#experience')}>.xp()</a></>
        }

        return (
            <div className="container pt-3 d-flex overflow-auto">
                <nav className="main-navbar d-flex flex-md-row flex-column navbar navbar-expand navbar-custom">
                    <div className="d-flex flex-row">
                        <a className="nav-link" href="/">.home()</a>
                        {navigation}
                        <Link className="nav-link" to="/posts/">.posts()</Link>
                        {/* <AnchorLink className="nav-link" to="#contact">.contact()</AnchorLink> */}
                    </div>
                </nav>
            </div>
        )
    }
}

export default Navigation
